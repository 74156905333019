import React from "react"

import { Disclosure, Transition } from "@headlessui/react"

import { Icon } from "src/assets/Icon"

import { Router } from "../anchor/Link"
import { useTranslation } from "next-i18next"

type Props = {
  title: string
  links: Array<{ title: string; href: string }>
  withBorderTop?: boolean
  translate?: boolean
}

export const AccordionMobile = ({ title, links, withBorderTop, translate = true }: Props) => {
  const { t } = useTranslation("common")

  return (
    <Disclosure>
      {({ open }) => (
        <div className={withBorderTop ? "border-y" : "border-b"}>
          <Disclosure.Button className="flex w-full items-center justify-between py-4 text-sm">
            <h3 className="font-tbcx-medium">{title}</h3>

            <Icon name="ChevronDownIcon" className={`fill-secondary-100 ${open ? "rotate-180" : ""} transition-all`} />
          </Disclosure.Button>

          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0">
            <Disclosure.Panel static>
              <ul className="mr-5 flex flex-col pb-4 text-gray-100">
                {links.map((item, id) => {
                  return (
                    <li key={id} className="overflow-hidden text-ellipsis font-tbcx-regular text-xs leading-7 text-secondary-70">
                      <Router href={item.href}>{translate ? t(item.title) : item.title}</Router>
                    </li>
                  )
                })}
              </ul>
            </Disclosure.Panel>
          </Transition>
        </div>
      )}
    </Disclosure>
  )
}
