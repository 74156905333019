import tw from "tailwind-styled-components"

// Header.styles.ts
export const HeaderBackground = tw.header`z-40 bg-white md:shadow-header sticky top-0 border-b md:border-none`
export const HeaderContainer = tw.div`desktop:flex items-center justify-between container desktop:h-[74px] w-full mx-auto xl:px-[100px]  desktop:px-[50px] p-4`

export const HeaderNavLinks = tw.div`flex items-stretch gap-7 mr-auto`

export const OuterContainer = tw.div` relative bg-white bg-clip-border`
export const DivColSpan = tw.div`col-span-3`

export const MainLogo = tw.div`py-2`
export const UserIcon = tw.div`ml-7`
export const SignIn = tw.span`border border-neutral-40 cursor-pointer text-2sm font-tbcx-medium items-center  bg-gray-10 px-5 py-2  flex justify-center hover:bg-neutral-20 transition rounded-xl`
export const Signature = tw.div`w-10 h-10 flex justify-center items-center bg-blue-50 rounded-full ml-4`

export const MobileTopMenuContainer = tw.div`flex justify-between desktop:gap-5 mobile:gap-2 desktop:hidden relative z-10` // Adjust z-index if needed
export const MobileBurgerMenuIconContainer = tw.div`mt-1 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full`
export const HeaderButtonBox = tw.div`flex justify-center items-center rounded-full w-11 h-11 hover:bg-gray-10 cursor-pointer`

export const MobileHeaderBox = tw.div`flex flex-col items-center justify-center w-16 h-full gap-1 hover:text-black hover:font-bold`

// export const Zura =tw.div`w-full tablet:col-span-9 flex mb-4`
