import { useQuery } from "@tanstack/react-query"
import { API_URL } from "src/env"
import { QUERY_KEYS } from "src/server/queryHooks/queryKeys"
import SEOService from "src/server/services/SEOService"
import { IActiveCategory } from "src/types/SEOTypes"

export const useActiveCategoriesQuery = () => {
  return useQuery<IActiveCategory[]>({
    queryKey: [QUERY_KEYS.ACTIVE_CATEGORIES],
    queryFn: getActiveCateogires
  })
}

export const getActiveCateogires = async (req?: any) => {
  try {
    const response: any = await SEOService.getActiveCategories(req)

    return response.data
  } catch (error: any) {
    throw new Error("Failed to fetch getActiveCateogires", error)
  }
}
