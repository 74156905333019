import { Icon } from "src/assets/Icon"
import { Router } from "src/views/components/anchor/Link"

interface TnetListProps {
  icon: JSX.Element
  title: string
  href: string
  listClassName?: string
}

interface TNETProps {
  myAutoIconClass?: string
  myAutoClass?: string
  myHomeIconClass?: string
  myHomeClass?: string
  myMarketIconClass?: string
  myMarketClass?: string
  myPartsIconClass?: string
  myPartsClass?: string
  vendooIconClass?: string
  vendooClass?: string
  swoopIconClass?: string
  swoopClass?: string
  tktIconClass?: string
  tktClass?: string
  livoIconClass?: string
  livoClass?: string
  sabaIconClass?: string
  sabaClass?: string
  superAppIconClass?: string
  superAppClass?: string
  className?: string
  listClassName?: string
  linkClassName?: string
  iClassname?: string
  myJobsIconClass?: string
  myJobsClass?: string

  myAutoListClassName?: string
  myPartsListClassName?: string
  myHomeListClassName?: string
  myMarketListClassName?: string
  vendooListClassName?: string
  swoopListClassName?: string
  tktListClassName?: string
  livoListClassName?: string
  sabaListClassName?: string
  superAppListClassName?: string
  myJobsListClassName?: string
  languageCode?: string
}

export const TNETProducts = (props: TNETProps) => {
  const TnetList: TnetListProps[] = [
    {
      icon: (
        <>
          <i className={`${props.iClassname} ${props.myAutoClass}`}>
            <Icon name="MyAutoIcon" className={props.myAutoIconClass} />
          </i>
        </>
      ),
      title: "myAuto",
      href: `https://www.myauto.ge/${props.languageCode}`,
      listClassName: props.myAutoListClassName
    },
    {
      icon: (
        <>
          <i className={`${props.myHomeClass}`}>
            <Icon name="LogoIcon" className={props.myHomeIconClass} />
          </i>
        </>
      ),
      title: "myHome",
      href: `https://www.myhome.ge/${props.languageCode}`,
      listClassName: props.myHomeListClassName
    },
    {
      icon: (
        <>
          <i className={`${props.myMarketClass}`}>
            <Icon name="MyMarketIcon" className={props.myMarketIconClass} />
          </i>
        </>
      ),
      title: "myMarket",
      href: `https://www.mymarket.ge/${props.languageCode}`,
      listClassName: props.myMarketListClassName
    },
    {
      icon: (
        <>
          <i className={`${props.myPartsClass}`}>
            <Icon name="MyPartsIcon" className={props.myPartsIconClass} />
          </i>
        </>
      ),
      title: "myParts",
      href: `https://www.myparts.ge/${props.languageCode}`,
      listClassName: props.myPartsListClassName
    },
    {
      icon: (
        <>
          <i className={`${props.iClassname} ${props.myJobsClass}`}>
            <Icon name="MyJobsIcon" className={props.myJobsIconClass} />
          </i>
        </>
      ),
      title: "myAuto",
      href: `https://www.myjobs.ge/${props.languageCode}`,
      listClassName: props.myJobsListClassName
    },

    {
      icon: (
        <>
          <i className={`${props.swoopIconClass}`}>
            <Icon name="SwoopIcon" className={props.swoopClass} />
          </i>
        </>
      ),
      title: "swoop",
      href: `https://www.swoop.ge/${props.languageCode}`,
      listClassName: props.swoopListClassName
    },
    {
      icon: (
        <>
          <i className={`${props.tktIconClass}`}>
            <Icon name="TktIcon" className={props.tktClass} />
          </i>
        </>
      ),
      title: "tkt",
      href: `https://www.tkt.ge/${props.languageCode}`,
      listClassName: props.tktListClassName
    },
    {
      icon: (
        <>
          <i className={`${props.livoIconClass}`}>
            <Icon name="LivoIconFooter" className={props.livoClass} />
          </i>
        </>
      ),
      title: "livo",
      href: `https://livo.ge/${props.languageCode}`,
      listClassName: props.livoListClassName
    },

    {
      icon: (
        <>
          <i className={`${props.superAppIconClass}`}>
            <Icon name="SuperAppIcon" className={props.superAppClass} />
          </i>
        </>
      ),
      title: "superApp",
      href: `https://superapp.tnet.ge/${props.languageCode}`,
      listClassName: props.superAppListClassName
    }

    // {
    //   icon: <Image src='/images/saba.png' width='51' height='24' alt='saba' />,
    //   title: 'saba',
    //   href: 'https://saba.com.ge/',
    //   listClassName: props.sabaListClassName
    // },
  ]

  return (
    <ul className={props.className}>
      {TnetList.map((item, index) => (
        <li key={index} className={`${props.listClassName ? props.listClassName : ""} ${item.listClassName}`}>
          <Router href={item.href} key={index} className={props.linkClassName} target="_blank">
            {item.icon}
          </Router>
        </li>
      ))}
    </ul>
  )
}
