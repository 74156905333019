import { HR_URL } from "src/env"

interface SocialLink {
  title: any
  href: string
  icon: "FacebookIcon" | "InstagramIcon" | "LinkedinIcon"
}

export const footerNavList = [
  {
    title: "MY_PAGE",
    href: `/profile/`
  },
  {
    title: "GENERATE_RESUME",
    href: `/cv/`
  },
  {
    title: "REGISTER_COMPANY",
    href: HR_URL
  },
  {
    title: "CREATE_PROFILE",
    href: `/register-wizard/`
  },
  {
    title: "EDIT_INFO",
    href: `/global/profile`
  }
]

export const supportList = [
  {
    title: "ASKED_QUESTIONS", // i18n key
    href: `/faq/`
  },

  {
    title: "032 2 80 00 25",
    href: `tel:0322800025`
  },
  {
    title: "Info@myjobs.ge",
    href: `mailto:info@myjobs.ge`
  },
  {
    title: "ANONYMOUS_RESPONSE",
    href: `https://guest-anonymous-hotline.tnet.ge/`,
    isBlank: true
  }
]

export const socialList: SocialLink[] = [
  { title: "facebook", href: "https://www.facebook.com/Myhome.ge/", icon: "FacebookIcon" },
  { title: "instagram", href: "https://www.instagram.com/myhomege/", icon: "InstagramIcon" },
  { title: "linkedin", href: "https://www.linkedin.com/company/tnetgeorgia/posts/?feedView=all", icon: "LinkedinIcon" }
]

export const companyRules = [
  {
    title: "RULES",
    href: `https://auth.tnet.ge/rules`
  },
  {
    title: "CONFEDENTIAL_POLITICS",
    href: `https://auth.tnet.ge/usageagreement`
  },
  {
    title: "ENVIRONMENTAL_POLITICS",
    href: `https://auth.tnet.ge/environmental`
  }
]
