import React from "react"
import Link from "next/link"
import Image from "next/image"

const HeaderLogo = () => {
  return (
    <Link href="/">
      <Image src="/images/myjobs_logo.svg" alt="myjobs logo" width={128} height={40} className="mobile:hidden desktop:flex" />
    </Link>
  )
}

export default HeaderLogo
