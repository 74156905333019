import { Listbox } from "@headlessui/react"
import { Fragment, useEffect, useState } from "react"

// ** Tippy JS
import "tippy.js/dist/tippy.css" // optional for styling

import { useQuery } from "@tanstack/react-query"
import { cx } from "class-variance-authority"
import i18n from "i18next"
import { useTranslation } from "next-i18next"
import Image from "next/image"
import { useRouter } from "next/router"
import { ACCESS_TOKEN_NAME } from "src/env"
import useOriginUrl from "src/hooks/useOriginUrl"
import usePersonApi from "src/server/queryHooks/usePersonApi"
import useVacancy from "src/server/queryHooks/useVacancy"
import { CacheTime } from "src/utils/configs/apiCacheTime"
import Cookie from "src/utils/configs/Cookie"
import Typography from "src/views/components/typography"
import { QUERY_KEYS } from "../../../server/queryHooks/queryKeys"
import { fetchPopularCategories } from "../../../server/queryHooks/useIndex"
import { IPublicCategoryResponse } from "../../../types/commonTypes"

const languagesData = [
  { id: 1, value: 1, label: "ქართული", title: "ka" },
  { id: 2, value: 2, label: "English", title: "en" }
]

const LanguageDropdownMenu = ({ isMobile = false }: any) => {
  const { t } = useTranslation("common")
  const router = useRouter()
  const { pathname, asPath, query } = router

  const { setLanguageMutation } = usePersonApi()

  const [selected, setSelected] = useState(languagesData[0])

  const { setLanguage } = useOriginUrl()

  const getLanguageFromUrlOrCookie = () => {
    const urlLanguage = typeof window !== "undefined" ? window.location.pathname.split("/")[1] : null
    if (urlLanguage === "en" || urlLanguage === "ka") {
      return urlLanguage
    }

    const cookieLanguage = Cookie.get("NEXT_LOCALE") || "ka"
    return cookieLanguage
  }

  useEffect(() => {
    const storedLanguage = getLanguageFromUrlOrCookie()
    if (storedLanguage) {
      languagesData.forEach(language => {
        if (language.title == storedLanguage) {
          setSelected(language)
        }
      })
    } else {
      setSelected(languagesData[0])
    }
  }, [])

  const selectLanguage = (event: any) => {
    setSelected(event)
    setLanguage(event.title)

    i18n.changeLanguage(event.title)

    document.cookie = `NEXT_LOCALE=${event.title}; max-age=31536000; path=/`
    router.push({ pathname, query }, asPath, { locale: event.title })

    if (Cookie.get(ACCESS_TOKEN_NAME)) {
      setLanguageMutation.mutate({ language_code: event.title }, {})
    }
  }

  return (
    <Listbox
      value={selected || languagesData[0]}
      onChange={event => selectLanguage(event)}
      as="div"
      className={cx("relative z-10 desktop:block", !isMobile ? "mobile:hidden" : "mt-3")}>
      {({ open }) => (
        <>
          <Listbox.Button
            className={cx(
              "flex items-center gap-2 rounded-xl px-3 py-2 outline outline-1 outline-raisin-20  transition-all hover:bg-[#F2F3F6] hover:outline-raisin-30",
              open ? "outline-secondary-100 hover:outline-secondary-100" : ""
            )}>
            <Image src="/images/language.svg" width={24} height={24} alt="language" />

            <Typography type="text" weight="medium" className="text-raisin-100">
              {selected.label}
            </Typography>

            <div
              className={cx(
                `transform stroke-raisin-100 transition-all duration-300`,
                open ? "rotate-180	stroke-raisin-100" : "rotate-0	stroke-raisin-100"
              )}>
              <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Group 33866">
                  <path id="Vector 2" d="M1.34766 1.5L4.34766 4.5L7.34766 1.5" stroke="inherit" strokeWidth="1.5" strokeLinecap="round" />
                </g>
              </svg>
            </div>
          </Listbox.Button>

          <Listbox.Options
            className="absolute mt-3 w-[224px] rounded-xl bg-white pb-2.5  pt-5 focus:outline-none"
            style={{
              filter: "drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.20))"
            }}>
            <Typography type="small" className="pl-6">
              {t("LANGUAGE")}
            </Typography>
            <ul className="mt-2.5">
              {languagesData.map(option => (
                <Listbox.Option key={option.id} value={option} as={Fragment}>
                  {({ active, selected }) => (
                    <li
                      className={cx(
                        "flex cursor-pointer select-none items-center gap-4 bg-white py-2.5 pl-6 transition-all hover:bg-raisin-10"
                      )}>
                      <div
                        className={cx("h-6 w-6 rounded-full border", selected ? "border-8 border-primary-100" : " border-[#A9AAAE]")}></div>
                      <Typography type="text" weight="medium" className="text-raisin-130">
                        {option.label}
                      </Typography>
                    </li>
                  )}
                </Listbox.Option>
              ))}
            </ul>
          </Listbox.Options>
        </>
      )}
    </Listbox>
  )
}

export default LanguageDropdownMenu
