import tw from "tailwind-styled-components"

export const Footer = tw.footer` w-[92%] mx-auto mobile:hidden desktop:p-4 desktop:block md:items-center md:justify-between md:p-6 desktop:mt-0 pt-8 desktop:pt-14 desktop:pb-14`
export const MobileFooter = tw.footer`h-[72px] w-full flex sticky bottom-0 z-10 text-xs font-normal justify-around bg-white items-center shadow shadow-header md:hidden`
export const HomeListBox = tw.div`pt-4 grid grid-cols-4 gap-1 max-w-[1240px] m-auto`

export const ColSpanOne = tw.div`col-span-1`
export const ColSpanTwo = tw.div`col-span-2`

export const DivOne = tw.div`flex justify-start items-center gap-3 mt-2`
export const IconDiv = tw.div`p-2 bg-gray-10 rounded-full hover:bg-gray-20`
export const GridDiv = tw.div`grid grid-cols-2 gap-3 mt-3`

export const SubLine = tw.div`flex items-center justify-between pt-10 max-w-[1240px] m-auto`
export const SubDiv = tw.div`flex justify-start items-center gap-4`

export const ContainerDiv = tw.div`max-w-[1240px] m-auto mt-10`
export const LogoDiv = tw.div`flex flex-row justify-center gap-2 items-center border border-gray-20 w-fit rounded-full`
export const Box = tw.div`flex flex-col mt-10 gap-3 first:mt-0 desktop:mt-0`

export const TitleList = tw.span`font-tbcx-bold text-[16px]`
export const VendooCommunication = tw.div`flex justify-center mt-10`
export const FooterBox = tw.footer`lg:border-t lg:border-gray-20 pt-12 lg:pt-6 bg-white md:mt-auto`
