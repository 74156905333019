"use client"

import { FC, useState } from "react"
import dynamic from "next/dynamic"
import Image from "next/image"
import Link from "next/link"
import { useTranslation } from "next-i18next"

// Import styles and components
import "tippy.js/dist/tippy.css" // optional for styling
import { HeaderBackground, HeaderContainer, MobileBurgerMenuIconContainer, MobileTopMenuContainer } from "./Header.styles"
import Typography from "src/views/components/typography"
import TntLogo from "src/views/components/logos"
import TnetMobLogo from "src/views/components/logos/mobileLogo"
import HeaderLogo from "./HeaderLogo"
import Badge from "../../atoms/Badge"

// Import constants and hooks
import { HR_URL } from "src/env"

// Dynamically import client-side components
const MobileDropdownMenu = dynamic(() => import("src/views/pages/main/MobileDropdownMenu"), { ssr: false })
const HeaderUserActionButtons = dynamic(() => import("./components/HeaderUserActionButtons"), { ssr: false })

interface Props {
  style?: { filter: any }
}

const headerLinks = [
  { label: "VACANCIES", href: "/vacancy" },
  { label: "COMPANIES", href: "/companies" },
  { label: "CREATE_RESUME", href: "/cv" }
]

const MainHeader: FC<Props> = ({ style }) => {
  const { t } = useTranslation("common")
  const [showMobileDropdownMenu, setShowMobileDropdownMenu] = useState(false)

  return (
    <HeaderBackground style={style}>
      <HeaderContainer>
        <div className="hidden items-center gap-4 desktop:flex xl:gap-9">
          <HeaderLogo />

          {headerLinks.map(({ label, href }) => (
            <Link
              key={href}
              href={href}
              className="flex items-center gap-1 border-b-4 border-transparent py-[25px] hover:border-primary-100">
              <Typography type="text">{t(label)}</Typography>
              {href === "/cv" && <Badge text={t("FREE")} />}
            </Link>
          ))}
        </div>

        <div className="hidden items-center gap-3 desktop:flex">
          <Link
            className="flex items-center justify-center gap-2 rounded-xl bg-primary-100/10 px-5 py-3 transition-all hover:bg-primary-100/20"
            href={HR_URL}
            target="_blank">
            <Image src={"/images/plus.svg"} width={16} height={16} alt="add" />
            <Typography type="small" weight="medium" className="text-primary-100">
              {t("ADD_VACANCY")}
            </Typography>
          </Link>

          <HeaderUserActionButtons />

          <TntLogo />
        </div>

        <MobileTopMenuContainer>
          <Link href="/">
            <Image src="/images/myjobs_logo.svg" alt="myjobs logo" width={128} height={40} />
          </Link>

          <div className="flex">
            <MobileBurgerMenuIconContainer onClick={() => setShowMobileDropdownMenu(true)}>
              <Image src="/icons/burger-menu.svg" alt="menu-logo" width={20} height={20} className="mr-4" />
            </MobileBurgerMenuIconContainer>
            <TnetMobLogo />
            {showMobileDropdownMenu && <MobileDropdownMenu setShowMobileDropdownMenu={setShowMobileDropdownMenu} />}
          </div>
        </MobileTopMenuContainer>
      </HeaderContainer>
    </HeaderBackground>
  )
}

export default MainHeader
