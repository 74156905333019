import Typography from "../typography"

import { useEffect, useState } from "react"
import { ACCESS_TOKEN_NAME } from "src/env"

import { Disclosure, Transition } from "@headlessui/react"
import dynamic from "next/dynamic"
import { Icon } from "src/assets/Icon"
import Cookie from "src/utils/configs/Cookie"
import { companyRules, footerNavList, supportList } from "src/utils/data/footer-data"
import { Router } from "../anchor/Link"
import { TNETProducts } from "../tnet-products/TNETProducts"
import { AccordionMobile } from "./AccordionMobile"
import { FooterBox } from "./style"
import useIndex from "src/server/queryHooks/useIndex"
import Link from "next/link"
import { useTranslation } from "next-i18next"
import LanguageDropdownMenu from "../LanguageDropdownMenu"
import { ICategory } from "src/types/commonTypes"
import { getLanguageFromUrlOrCookie } from "src/utils/helpers/Language"
import { useActiveCategoriesQuery } from "src/query-library/queries/useActiveCategoriesQuery"

const TopGe = dynamic(() => import("../top.ge/TopGe"), { ssr: false })

const DefaultFooter = () => {
  const { t } = useTranslation("common")
  const activeCategoriesQuery = useActiveCategoriesQuery()
  const categories = activeCategoriesQuery.data

  const [token, setToken] = useState(false)
  const [showTopGe, setShowTopGe] = useState(false)
  const [language, setLanguage] = useState("ka")

  useEffect(() => {
    setShowTopGe(true)

    const lang = getLanguageFromUrlOrCookie()
    setLanguage(lang)

    if (Cookie.get(ACCESS_TOKEN_NAME)?.length > 10) {
      setToken(true)
    }
  }, [])

  if (!categories) {
    return <div>Category not found</div>
  }

  return (
    <FooterBox className="pb-[70px]">
      <div className="container mx-auto w-full items-center justify-between desktop:flex desktop:px-[80px] xl:px-[100px] ">
        <div className="hidden justify-between gap-x-5 lg:flex xl:gap-x-[130px]">
          <div className="flex flex-col">
            <h3 className="mb-4 font-tbcx-medium">{t("NAVIGATION")}</h3>
            <ul className="mr-5 flex flex-col text-gray-100">
              {footerNavList.map((item, id) => {
                return (
                  <li
                    key={id}
                    className="underline-secondary-100 overflow-hidden text-ellipsis font-tbcx-regular text-xs leading-7 text-secondary-70 hover:underline">
                    <Router href={item.href}>
                      <Typography type="small" color="light">
                        {t(item.title)}
                      </Typography>
                    </Router>
                  </li>
                )
              })}
            </ul>
            <LanguageDropdownMenu />
          </div>
          <div className="flex flex-col">
            <h3 className="mb-4 font-tbcx-medium">{t("HELP")}</h3>
            <ul className="mr-5">
              {supportList.map((item, id) => {
                return (
                  <li
                    key={id}
                    className="underline-secondary-100 overflow-hidden text-ellipsis font-tbcx-regular text-xs leading-7 text-secondary-70 hover:underline">
                    {item.isBlank ? (
                      <Link href={item.href} target="_blank" rel="noopener noreferrer">
                        <Typography type="small" color="light">
                          {t(item.title)}
                        </Typography>
                      </Link>
                    ) : (
                      <Router href={item.href}>
                        <Typography type="small" color="light">
                          {t(item.title)}
                        </Typography>
                      </Router>
                    )}
                  </li>
                )
              })}
            </ul>
            {/* <ul className='mr-5 hidden gap-4 lg:flex'>
                {socialList.map((item, id) => {
                  return (
                    <li
                      key={id}
                      className='overflow-hidden text-ellipsis font-tbcx-regular text-xs leading-7 text-secondary-70'
                    >
                      <SocialButton href={item.href} icon={item.icon} />
                    </li>
                  )
                })}
              </ul> */}
          </div>
          <div>
            <h3 className="mb-4 font-tbcx-medium">{t("CATEGORIES")}</h3>
            <div>
              <ul className="grid grid-cols-3 gap-x-12">
                {categories?.map((item, id) => {
                  return (
                    <li
                      className="underline-secondary-100 overflow-hidden text-ellipsis font-tbcx-regular text-xs leading-7 text-secondary-70 hover:underline"
                      key={id}>
                      <Link
                        className="max-w-[200px]  overflow-hidden text-ellipsis whitespace-nowrap text-xs"
                        href={`/vacancy/?categories=${item?.id}`}>
                        <Typography type="small" color="light">
                          {item?.title}
                        </Typography>
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="mb-6 lg:hidden">
          <AccordionMobile title={t("NAVIGATION")} links={footerNavList} withBorderTop />
          <AccordionMobile title={t("HELP")} links={supportList} />
          <AccordionMobile
            title={t("CATEGORIES")}
            links={categories.map(category => {
              category.href = `/vacancy/?categories=${category?.id}`
              return category
            })}
            translate={false}
          />
          <LanguageDropdownMenu isMobile={true} />
        </div>
      </div>
      <div className="bg-white-100 mb-4 mt-14 hidden border-t border-secondary-10 lg:flex"></div>
      <div className="container mx-auto w-full items-center justify-between desktop:flex desktop:px-[80px] xl:px-[100px] ">
        <div className="flex w-full  flex-col flex-wrap items-baseline gap-6 lg:flex-row lg:items-center">
          <ul className="mr-5 flex flex-col gap-0 text-secondary-100 lg:flex-row lg:gap-6">
            {companyRules.map((item, id) => {
              return (
                <li key={id} className="font-tbcx-regular text-xs leading-7 underline transition-all hover:text-secondary-100">
                  <Router href={item.href} target="_blank">
                    <Typography type="small" color="light">
                      {t(item.title)}
                    </Typography>
                  </Router>
                </li>
              )
            })}
          </ul>
        </div>

        <div className="ml-auto flex w-full flex-1 items-center justify-end ">
          {/* <LangCurrencyPopover panelClassName='bottom-full mb-3' /> */}
          {showTopGe && <TopGe />}
        </div>
      </div>
      <div className="container desktop:px-[80px]">
        <div className="border-gray-30 mt-6 hidden w-full rounded-[64px] border-y xl:flex xl:border">
          <i className="mr-[30px] flex w-[130px] items-center rounded-[64px] bg-tnet-100 px-[26px] py-[22px]">
            <Icon name="TnetLogoFullIcon" className="w-20 fill-white" />
          </i>

          <TNETProducts
            className="flex w-full flex-wrap items-center justify-between pr-7"
            listClassName="hover:opacity-75 md:w-auto"
            myAutoIconClass="w-16 h-5"
            myAutoListClassName="order-1"
            myPartsListClassName="order-2"
            myPartsIconClass="w-[68px] h-5"
            myHomeIconClass="w-[65px] h-5"
            myHomeListClassName="order-3"
            myMarketIconClass="w-[78px] h-5"
            myJobsListClassName="order-5"
            myMarketListClassName="order-4"
            superAppIconClass="w-16 h-5"
            superAppListClassName="order-6"
            tktIconClass="w-[66px] h-4"
            tktListClassName="order-9"
            swoopIconClass="w-[84px] h-[18px]"
            swoopListClassName="order-10"
            livoIconClass="w-[52px] h-[18px]"
            livoListClassName="order-11"
            languageCode={language}
          />
        </div>
      </div>
      <div className="container mx-auto">
        <div className="mt-6 rounded-[24px] bg-[#F7F7F7] p-7 xl:hidden">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between">
                  <Icon name="TnetLogoFullIcon" className="fill-[#3C74FF]" />

                  <Icon name="CarretDownIcon" className={`${open ? "" : "rotate-180"} transition-all`} />
                </Disclosure.Button>

                <Transition
                  show={open}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0">
                  <Disclosure.Panel static>
                    <TNETProducts
                      className="flex w-full flex-wrap items-center gap-y-10  pt-10"
                      listClassName="hover:opacity-75 w-1/3 sm:w-1/4 md:w-1/6 lg:flex-1"
                      myAutoIconClass="w-16 h-5"
                      myAutoListClassName="order-1"
                      myPartsListClassName="order-2"
                      myPartsIconClass="w-[68px] h-5"
                      myHomeIconClass="w-[65px] h-5"
                      myHomeListClassName="order-3"
                      myMarketIconClass="w-[78px] h-5"
                      myMarketListClassName="order-4"
                      superAppIconClass="w-16 h-5"
                      superAppListClassName="order-5"
                      vendooIconClass="w-[81px] h-4"
                      vendooListClassName="order-7"
                      tktIconClass="w-[66px] h-4"
                      tktListClassName="order-8"
                      swoopIconClass="w-[84px] h-[18px]"
                      swoopListClassName="order-9"
                      livoIconClass="w-[52px] h-[18px]"
                      livoListClassName="order-10"
                      languageCode={language}
                    />
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        </div>
        <div className="my-4 flex items-center justify-center text-xs  leading-7 text-secondary-70">
          <p>{`© ${new Date().getFullYear()} ${t("ALL_RESERVED")}`}</p>
        </div>
      </div>
    </FooterBox>
  )
}

export default DefaultFooter
