import React, { useEffect } from "react"
import { useState } from "react"
import { Transition } from "@headlessui/react"
import { Title, Text } from "./style"
// ** Scroll Helper
import useBlockScroll from "src/utils/helpers/useBlockScroll"
import { useTranslation } from "next-i18next"
import { getLanguageFromUrlOrCookie } from "src/utils/helpers/Language"

const logos_array = [
  { img: "/icons/myauto.svg", key: "myauto", route: "https://myauto.ge/" },
  { img: "/icons/myhome.svg", key: "myhome", route: "https://myhome.ge/" },
  { img: "/icons/mymarket.svg", key: "mymarket", route: "https://mymarket.ge/" },
  { img: "/icons/myparts.svg", key: "myparts", route: "https://myparts.ge/" },
  { img: "/images/myjobs_logo.svg", key: "myjobs", route: "https://myjobs.ge/" },
  { img: "/icons/swoop.svg", key: "swoop", route: "https://swoop.ge/" },
  { img: "/icons/tkt.svg", key: "tkt", route: "https://tkt.ge/" },
  { img: "/icons/livo.svg", key: "livo", route: "https://livo.ge/" },
  { img: "/icons/saba.png", key: "saba", route: "https://saba.com.ge/" },
  { img: "/icons/SuperApp.svg", key: "SuperApp", route: "https://superapp.tnet.ge/" }
]

const TnetMobLogo = () => {
  const { t } = useTranslation("common")
  const [isOpen, setIsOpen] = useState(false)
  const [language, setLanguage] = useState("ka")
  const { allowScroll, blockScroll } = useBlockScroll()

  useEffect(() => {
    const lang = getLanguageFromUrlOrCookie()
    setLanguage(lang)
  }, [])

  const toggleLogo = () => {
    setIsOpen(!isOpen)
    if (!isOpen) {
      blockScroll()
    } else {
      allowScroll()
    }
  }

  return (
    <div>
      <button
        className={`flex h-10 w-10 items-center justify-center rounded-full border p-1.5 outline-none hover:border-[#3C74FF] ${
          isOpen ? "border-[#3C74FF] bg-[#3C74FF]" : "bg-transparent"
        }`}
        onClick={() => toggleLogo()}>
        <img src={`/icons/${isOpen ? "tnet_logo_white.svg" : "tnet_logo.svg"}`} alt="Logo" width={24} />
      </button>

      <Transition
        appear={true}
        show={isOpen}
        as={"div"}
        enter="transition-opacity duration-100"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        {isOpen && (
          <div>
            <div className="fixed inset-0 bg-black opacity-50" />

            <div className="custom-scroll fixed bottom-0 left-0 z-[640] h-[80%] w-full overflow-y-auto rounded-t-[20px] bg-white pb-20 md:pb-0">
              <div className="absolute right-7 top-5 z-[10] cursor-pointer">
                <img src="/icons/mobile_dropdown-close.svg" onClick={toggleLogo} alt="drp" />
              </div>
              <div className="flex flex-col items-center px-6 pb-3">
                <Title className="px-0">TNET</Title>
                <Text className="w-fit text-center">{t("BIGGEST_TECHNOLOGY_PLATFORM")}</Text>
                <div className="grid w-full grid-cols-2 gap-0 rounded-lg border-2 border-gray-200">
                  {logos_array.map((logo, key) => (
                    <a
                      href={`${logo.route}/${language}`}
                      target="_blank"
                      rel="noreferrer"
                      key={key}
                      // className={logo.key === "SuperApp" ? "col-span-2" : "col-span-1"}
                    >
                      <div
                        className={`flex h-20 items-center justify-center border-b border-gray-300 py-6 ${
                          [2, 4, 6, 8, 10].includes(key + 1) ? "" : "border-r"
                        }
                        ${logo.key === "SuperApp" || logo.key === "saba" ? " border-b-0" : ""}`}>
                        <img src={logo.img} width={100} alt={logo.route} />
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </Transition>
    </div>
  )
}

export default TnetMobLogo
