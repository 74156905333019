import React, { useEffect, useState } from "react"
import { Menu, Transition } from "@headlessui/react"
import { Fragment } from "react"
import { Title, Text, GridDiv, MainDiv } from "./style"
import CompanyLogo from "src/views/components/GeneralLogo/CompanyLogo"
import { useTranslation } from "next-i18next"
import { getLanguageFromUrlOrCookie } from "src/utils/helpers/Language"

const logos_array = [
  { img: "/icons/myauto.svg", key: "myauto", route: "https://myauto.ge/" },
  { img: "/icons/myhome.svg", key: "myhome", route: "https://myhome.ge/" },
  { img: "/icons/mymarket.svg", key: "mymarket", route: "https://mymarket.ge/" },
  { img: "/icons/myparts.svg", key: "myparts", route: "https://myparts.ge/" },
  { img: "/images/myjobs_logo.svg", key: "SuperApp", route: "https://superapp.tnet.ge/" },
  { img: "/icons/swoop.svg", key: "swoop", route: "https://swoop.ge/" },
  { img: "/icons/tkt.svg", key: "tkt", route: "https://tkt.ge/" },
  { img: "/icons/livo.svg", key: "livo", route: "https://livo.ge/" },
  { img: "/icons/saba.png", key: "saba", route: "https://saba.com.ge/" },
  { img: "/icons/SuperApp.svg", key: "SuperApp", route: "https://superapp.tnet.ge/" }
]

const TntLogo = () => {
  const { t } = useTranslation("common")
  const [language, setLanguage] = useState("ka")

  useEffect(() => {
    const lang = getLanguageFromUrlOrCookie()
    setLanguage(lang)
  }, [])

  return (
    <MainDiv>
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <Menu.Button
              className={`flex h-10 w-10 items-center justify-center rounded-full border p-1.5 transition-all duration-500 hover:border-[#3C74FF] ${
                open ? "border-[#3C74FF] bg-[#3C74FF]" : "bg-transparent"
              }`}>
              <img src={`/icons/${open ? "tnet_logo_white.svg" : "tnet_logo.svg"}`} alt="Logo" width={24} />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="absolute right-0 z-10 mt-2 w-[378px] origin-top-right cursor-pointer divide-gray-100 rounded-md bg-white py-2.5 shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none ">
                <Menu.Item>
                  <>
                    <Title>TNET</Title>
                    <Text>{t("BIGGEST_TECHNOLOGY_PLATFORM")}</Text>
                    <GridDiv>
                      {logos_array.map((logo, key) => (
                        <a
                          href={`${logo.route}${language}`}
                          target="_blank"
                          className={`${logo.key === "vendoo" ? "bg-gray-10" : ""} hover:bg-gray-10 flex justify-center rounded-lg`}
                          rel="noreferrer"
                          key={key}>
                          <img
                            src={logo.img}
                            alt={logo.route}
                            className={`${
                              logo.key === "mymarket" ? "p-4" : logo.key === "livo" ? "p-8" : logo.key === "saba" ? "p-8" : "p-6"
                            }`}
                          />
                        </a>
                      ))}
                    </GridDiv>
                  </>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </MainDiv>
  )
}
export default TntLogo
