import React, { FC } from "react"
import Typography from "../typography"

interface Props {
  text: string
}
const Badge: FC<Props> = ({ text }) => {
  return (
    <Typography type="small" className="bg-primary-100 text-white  px-2 pb-[2.5px] pt-[1px] rounded-full text-[11px]">
      {text}
    </Typography>
  )
}

export default Badge
