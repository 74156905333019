import Link from "next/link"

import tw from "tailwind-styled-components"

const Anchor = tw(Link)`
  outline-none

`

interface StyledLinkProps {
  href: string
  children: React.ReactNode
  target?: string
  className?: string
  onClick?: React.MouseEventHandler
}

export const Router: React.FC<StyledLinkProps> = ({ children, className, ...rest }) => {
  return (
    <Anchor {...rest} className={className}>
      {children}
    </Anchor>
  )
}
